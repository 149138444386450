
import { kioskModule } from '@/store/kiosk.vuex-module';
import { Options, Vue } from 'vue-class-component';

@Options({
	name: 'CheckinView',
	components: {},
})
export default class CheckinView extends Vue {
	isLoading = false;

	get logoUrl() {
		return kioskModule.clientLogoUrl;
	}

	get clientName() {
		return `${kioskModule.clientName} ${kioskModule.clientLocationName ?? ''}`;
	}

	created() {
		this.isLoading = true;
		kioskModule.setOnSite(this.$route.params.onsite === 'true');

		if (this.$route.query.unassigned) kioskModule.setUnassigned(true);

		kioskModule.initSession();
		if ((kioskModule.orderKey && kioskModule.orderKey != this.$route.params.orderKey) || this.$route.params.orderKey == '0') {
			kioskModule.resetSession();
		}

		kioskModule
			.getClientLocationData(this.$route.params.clientCode as string)
			.then((res) => {
				if (this.$route.name == 'Expired') return;
				if (res) {
					this.setStyle(res);
					if (this.$route.query.unassigned) {
						// no order associated with consent
						this.$router.push({ name: 'AdultCheckin', query: { ...this.$route.query } });
					} else if (this.$route.params.orderKey != '0') {
						// lookup order
						kioskModule
							.getOrderData(this.$route.params.orderKey as string)
							.then((res) => {
								console.log('RR', res);
								if (res) {
									if (kioskModule.expiredOrder) this.$router.push({ name: 'Expired' });
									else this.$router.push({ name: kioskModule.session.lastPage ?? 'AdultCheckin', query: { ...this.$route.query } });
								} else {
									kioskModule.resetSession();
									this.goToAppointments();
								}
							})
							.catch((e) => {
								if (e == 'Error: Conflict') {
									kioskModule.resetSession();
									this.$router.push({ name: 'Expired', query: { link: 'true' } });
								} else {
									kioskModule.resetSession();
									this.goToAppointments();
								}
							});
					} else this.goToAppointments();
				} else throw new Error(`Error getting client '${this.$route.params.clientCode}' location data.`);
			})
			.catch((e) => {
				console.error('E', e);
				kioskModule.resetSession();
				console.log('here');
				this.$router.push({ name: 'PageNotFound' });
			})
			.finally(() => {
				this.isLoading = false;
			});
	}

	// go to appointments list
	goToAppointments() {
		this.$router.push({
			name: 'AppointmentsView',
		});
	}

	getClientStyle(key: number) {
		try {
			return require(`@/assets/client/${key}/style.json`);
		} catch (e) {
			return null;
		}
	}

	setStyle(key: number) {
		// set global css variables
		const style = this.getClientStyle(key);
		console.log('Loaded style', style);
		if (style) {
			document.documentElement.style.setProperty('--theme-color', style['--theme-color']);
		}
	}
}
